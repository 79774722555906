import React,{Component} from 'react';
import { Menu, Popup, Icon } from 'semantic-ui-react';

class MenuDeGauche extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            activeItem : this.props.activeItem,
        }
        this.handleOnClickSizePlus = this.handleOnClickSizePlus.bind(this);
        this.handleOnClickSizeMinus = this.handleOnClickSizeMinus.bind(this);
        this.handleOnClickCompact = this.handleOnClickCompact.bind(this);
        this.handleOnClickExpand = this.handleOnClickExpand.bind(this);
        this.handleOnClickTheme = this.handleOnClickTheme.bind(this);
    }

    handleOnClickSizePlus(e){
        this.props.changerTaille('plus');
    }

    handleOnClickSizeMinus(e){
        this.props.changerTaille('moins');
    }

    handleOnClickCompact(e){
        this.setState({ activeItem: 'compress'})
        this.props.changeActiveItem('expand');
    }

    handleOnClickExpand(e){
        this.setState({ activeItem: 'expand'})
        this.props.changeActiveItem('compact');
    }

    handleOnClickTheme(e){
        this.props.changerTheme();
    }
    
    render() { 
        return ( 
            <div className="menudegauche">
            <Menu compact icon >
                    <Popup content='Diminuer la police' trigger={<Menu.Item
                    name='moins' id='moins'
                    onClick={this.handleOnClickSizeMinus}
                    >
                    <Icon name='search minus' />
                    </Menu.Item>
                    } position='bottom center'/>
                    
                    <Popup content='Augmenter la police' trigger={<Menu.Item
                    name='plus' id='plus'
                    onClick={this.handleOnClickSizePlus}
                    >
                    <Icon name='search plus' />
                    </Menu.Item>
                    } position='bottom center'/>

                    { this.state.activeItem !== 'compress' ?
                    <Popup content='Compacter le texte' trigger={<Menu.Item
                    name='compact' 
                    onClick={this.handleOnClickCompact}>
                    <Icon name='compress' />
                    </Menu.Item>
                    } position='bottom center'/>
                    :
                    <Popup content='Aérer le texte' trigger={<Menu.Item
                    name='expand' 
                    onClick={this.handleOnClickExpand}>
                    <Icon name='expand' />
                    </Menu.Item>
                    } position='bottom center'/>
                    }

                    <Popup content='Changer le thème de lecture' trigger={<Menu.Item
                    name='theme' id='theme'
                    onClick={this.handleOnClickTheme}
                    >
                    <Icon name='paint brush' />
                    </Menu.Item>
                    } position='bottom center'/>
                </Menu>
                </div>
         );
    }
}
 
export default MenuDeGauche;