import React,{ useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Icon, Card, Dimmer, Loader} from 'semantic-ui-react';
import Page from "./partsLire/Page";
import MenuDeDroite from "./partsLire/MenuDeDroite";
import MenuDeGauche from "./partsLire/MenuDeGauche";
import BasDePage from "./partsLire/BasDePage";
import "./Lire.css";
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";


const options0 = [
    { key: 0, text: 'Genèse', value: 'Genèse' },
    { key: 1, text: 'Exode', value: 'Exode' },
    { key: 2, text: 'Lévitique', value: 'Lévitique' },
    { key: 3, text: 'Nombres', value: 'Nombres' },
    { key: 4, text: 'Deutéronome', value: 'Deutéronome' },
    { key: 5, text: 'Josué', value: 'Josué' },
    { key: 6, text: 'Juges', value: 'Juges' },
    { key: 7, text: 'Ruth', value: 'Ruth' },
    { key: 8, text: '1 Samuel', value: '1Samuel' },
    { key: 9, text: '2 Samuel', value: '2Samuel' },
    { key: 10, text: '1 Rois', value: '1Rois' },
    { key: 11, text: '2 Rois', value: '2Rois' },
    { key: 12, text: '1 Chroniques', value: '1Chroniques' },
    { key: 13, text: '2 Chroniques', value: '2Chroniques' },
    { key: 14, text: 'Esdras', value: 'Esdras' },
    { key: 15, text: 'Néhémie', value: 'Néhémie' },
    { key: 16, text: 'Esther', value: 'Esther' },
    { key: 17, text: 'Job', value: 'Job' },
    { key: 18, text: 'Psaumes', value: 'Psaumes' },
    { key: 19, text: 'Proverbes', value: 'Proverbes' },
    { key: 20, text: 'Ecclésiaste', value: 'Ecclésiaste' },
    { key: 21, text: 'Cantique', value: 'Cantique' },
    { key: 22, text: 'Ésaïe', value: 'Esaïe' },
    { key: 23, text: 'Jérémie', value: 'Jérémie' },
    { key: 24, text: 'Lamentations', value: 'Lamentations' },
    { key: 25, text: 'Ézéchiel', value: 'Ezéchiel' },
    { key: 26, text: 'Daniel', value: 'Daniel' },
    { key: 27, text: 'Osée', value: 'Osée' },
    { key: 28, text: 'Joël', value: 'Joël' },
    { key: 29, text: 'Amos', value: 'Amos' },
    { key: 30, text: 'Abdias', value: 'Abdias' },
    { key: 31, text: 'Jonas', value: 'Jonas' },
    { key: 32, text: 'Michée', value: 'Michée' },
    { key: 33, text: 'Nahum', value: 'Nahum' },
    { key: 34, text: 'Habakuk', value: 'Habakuk' },
    { key: 35, text: 'Sophonie', value: 'Sophonie' },
    { key: 36, text: 'Aggée', value: 'Aggée' },
    { key: 37, text: 'Zacharie', value: 'Zacharie' },
    { key: 38, text: 'Malachie', value: 'Malachie' },
    { key: 39, text: 'Matthieu', value: 'Matthieu' },
    { key: 40, text: 'Marc', value: 'Marc' },
    { key: 41, text: 'Luc', value: 'Luc' },
    { key: 42, text: 'Jean', value: 'Jean' },
    { key: 43, text: 'Actes', value: 'Actes' },
    { key: 44, text: 'Romains', value: 'Romains' },
    { key: 45, text: '1 Corinthiens', value: '1Corinthiens' },
    { key: 46, text: '2 Corinthiens', value: '2Corinthiens' },
    { key: 47, text: 'Galates', value: 'Galates' },
    { key: 48, text: 'Éphésiens', value: 'Ephésiens' },
    { key: 49, text: 'Philippiens', value: 'Philippiens' },
    { key: 50, text: 'Colossiens', value: 'Colossiens' },
    { key: 51, text: '1 Thessaloniciens', value: '1Thessaloniciens' },
    { key: 52, text: '2 Thessaloniciens', value: '2Thessaloniciens' },
    { key: 53, text: '1 Timothée', value: '1Timothée' },
    { key: 54, text: '2 Timothée', value: '2Timothée' },
    { key: 55, text: 'Tite', value: 'Tite' },
    { key: 56, text: 'Philémon', value: 'Philémon' },
    { key: 57, text: 'Hébreux', value: 'Hébreux' },
    { key: 58, text: 'Jacques', value: 'Jacques' },
    { key: 59, text: '1 Pierre', value: '1Pierre' },
    { key: 60, text: '2 Pierre', value: '2Pierre' },
    { key: 61, text: '1 Jean', value: '1Jean' },
    { key: 62, text: '2 Jean', value: '2Jean' },
    { key: 63, text: '3 Jean', value: '3Jean' },
    { key: 64, text: 'Jude', value: 'Jude' },
    { key: 65, text: 'Apocalypse', value: 'Apocalypse' }
  ];

const list1 = ["Genèse","Exode","Lévitique","Nombres","Deutéronome","Josué","Juges","Ruth","1Samuel","2Samuel","1Rois","2Rois","1Chroniques","2Chroniques","Esdras","Néhémie","Esther","Job","Psaumes","Proverbes","Ecclésiaste","Cantique","Esaïe","Jérémie","Lamentations","Ezéchiel","Daniel","Osée","Joël","Amos","Abdias","Jonas","Michée","Nahum","Habakuk","Sophonie","Aggée","Zacharie","Malachie","Matthieu","Marc","Luc","Jean","Actes","Romains","1Corinthiens","2Corinthiens","Galates","Ephésiens","Philippiens","Colossiens","1Thessaloniciens","2Thessaloniciens","1Timothée","2Timothée","Tite","Philémon","Hébreux","Jacques","1Pierre","2Pierre","1Jean","2Jean","3Jean","Jude","Apocalypse"];

function Lire() {
    
    const [totalChapitre, settotalChapitre] = useState(1189)
    const [listPlage, setlistPlage] = useState([50,40,27,36,34,24,21,4,31,24,22,25,29,36,10,13,10,42,150,31,12,8,66,52,5,48,12,14,3,9,1,4,7,3,3,3,2,14,4,28,16,24,21,28,16,16,13,6,6,4,4,5,3,6,4,3,1,13,5,5,3,5,1,1,1,22])
    const [activeItem, setactiveItem] = useState('compact')
    const [book, setbook] = useState('Genèse')
    const [chapiter, setchapiter] = useState(1)
    const [activePa, setactivePa] = useState(1)
    const [taille, settaille] = useState(1.5)
    const [theme, settheme] = useState('clair')
    const [bible, setbible] = useState('lsg')
    const [TheBook, setTheBook] = useState('Genèse')
    const [index, setindex] = useState(0)
    const [options, setoptions] = useState([
        { key: '1', text: 'Chapitre 1', value: 1 },
        { key: '2', text: 'Chapitre 2', value: 2 },
        { key: '3', text: 'Chapitre 3', value: 3 },
    ])
    const [listVerset, setlistVerset] = useState([])
    const [copyright, setcopyright] = useState('Cette Bible est dans le domaine public.')
    const [chargement, setchargement] = useState(true)

    const location = useLocation();

    const [envoieTermine, setenvoieTermine] = useState(false);

    const calculerPage = (i, list, chapitre) => {

        var somme = 0;
        for (const [index, value] of list.entries()) {
            if(index === i){
                break;
            }else{
                somme += parseInt(value);
            }
        }

        setactivePa(somme+chapitre);

    }

    const calculerLivreChapitre = async (page,list) => {

        let somme = 0;
        let position = 0;
        let lechapitre = 0;
        let lelivre = "";
        for (const [index, value] of list.entries()) {
            if(somme >= page){
                position = index-1;
                lelivre = list1[position];
                lechapitre = list[position]-(somme-page);
                break;
            }else{
                somme += parseInt(value);
                position = index;
                lelivre = list1[position];
                lechapitre = list[position]-(somme-page);
            }
        }

        await ajouterOptions(list,position);

        const livre = options0.find((element) => element.value === lelivre).text;
        const lindex = list1.indexOf(lelivre);

        setindex(lindex);
        setbook(lelivre);
        setTheBook(livre);
        setchapiter(lechapitre);

    }

    const ajouterOptions = async (list,index) => {

        let lesoptions = [];

        for(var n = 1; n <= list[index]; n++){
            lesoptions.push({
                key: `${n}`,
                text: `Chapitre ${n}`,
                value: n
            });
        }

        await setoptions(lesoptions);

    }

    useEffect(() => {

        //console.log(location.state);
      
        if(!location.state){

            setchargement(true);
            ajouterOptions(listPlage,index);
            setenvoieTermine(true);

        }

    }, [])

    useEffect(() => {

        if(location.state){

            setenvoieTermine(false);
            setbible(location.state.bible);
            setbook(list1[location.state.index]);
            setTheBook(list1[location.state.index].replace("1","1 ").replace("2","2 ").replace("3","3 "));
            setindex(parseInt(location.state.index));          
            setchapiter(parseInt(location.state.chapitre));
            setchargement(true);

            ajouterOptions(listPlage,location.state.index);

            calculerPage(location.state.index,listPlage,location.state.chapitre);  

        }

    }, [location.state])
    
    useEffect(() => {
      if(location.state){
            setenvoieTermine(true);
      }
    }, [activePa])
    

    const changerLivre = (value) => {
        
        const livre = options0.find((element) => element.value === value).text

        const lindex = list1.indexOf(value);

        ajouterOptions(listPlage,lindex);
        
        setbook(value);
        setTheBook(livre);
        setchapiter(1);
        setindex(lindex);

    }

    useEffect(() => {
        if(envoieTermine){
            calculerPage(index,listPlage,chapiter);
        }
    }, [index,listPlage,chapiter])

    useEffect(() => {
        if(envoieTermine){
            calculerLivreChapitre(activePa,listPlage);
            window.scrollTo(0, 0);
        }
    }, [activePa])

    useEffect(() => {

        setlistVerset([]);

        fetch(`https://api.web-labosims.org/bible/v1/${bible}/s/$2y$10$vHbj9FQKirh.S0bF6pLe3ugZdFHBwajZ2OyMRS8yAdVOCsA.zW8B2/${index+1}/${chapiter}`)
        .then((response) => response.json())
        .then((data) => {       
            setlistVerset(data);
            setchargement(false);
        })
        .catch((erreur) => console.log(erreur));

    }, [index, chapiter,bible])

    const changerChapitre = async (value) => {
        setchapiter(value);
    }

    const changerTaille = (valeur) => {
        if(valeur === 'moins'){
            if(taille > 1.0){
                settaille(taille - 0.1);
            }
        }else{
            if(taille < 2.5){
                settaille(taille + 0.1);
            }
        }
    }

    const changeActiveItem = (value) => {
        setactiveItem(value);
    }

    const changerBible = async (value) =>{
        if(value === "dby"){
            await settotalChapitre(1189);
            await setlistPlage([50,40, 27,36,34,24,21,4,31,24,22,25,29,36,10, 13,10,42,150,31,12,8,66,52,5,48,12,14,3,9,1,4,7,3,3,3,2,14,4,28,16,24,21,28,16,16,13,6,6,4,4,5,3,6,4,3,1,13,5,5,3,5,1,1,1,22]);
            await setcopyright("Cette Bible est dans le domaine public.");
        }else if(value === "lsg"){
            await settotalChapitre(1189);
            await setlistPlage([50,40,27,36,34,24,21,4,31,24,22,25,29,36,10,13,10,42,150,31,12,8,66,52,5,48,12,14,3,9,1,4,7,3,3,3,2,14,4,28,16,24,21,28,16,16,13,6,6,4,4,5,3,6,4,3,1,13,5,5,3,5,1,1,1,22]);
            await setcopyright("Cette Bible est dans le domaine public.");
        }else if(value === "mar"){
            await settotalChapitre(1189);
            await setlistPlage([50,40, 27,36,34,24,21,4,31,24,22,25,29,36,10, 13,10,42,150,31,12,8,66,52,5,48,12,14,3,9,1,4,7,3,3,3,2,14,4,28,16,24,21,28,16,16,13,6,6,4,4,5,3,6,4,3,1,13,5,5,3,5,1,1,1,22]);
            await setcopyright("Cette Bible est dans le domaine public.");
        }else if(value === "s21"){
            await settotalChapitre(118);
            await setlistPlage([50,40, 27,36,34,24,21,4,31,24,22,25,29,36,10, 13,10,42,150,31,12,8,66,52,5,48,12,14,3,9,1,4,7,3,3,3,2,14,4,28,16,24,21,28,16,16,13,6,6,4,4,5,3,6,4,3,1,13,5,5,3,5,1,1,1,22]);
            await setcopyright("Texte biblique de la Bible Version Segond 21<br/>Copyright © 2007 <a target='_blank' href='http://www.societebiblique.com'>Société Biblique de Genève</a><br/>Reproduit avec aimable autorisation. Tous droits réservés.");
        }else if(value === "neg"){
            await settotalChapitre(1189);
            await setlistPlage([50,40, 27,36,34,24,21,4,31,24,22,25,29,36,10, 13,10,42,150,31,12,8,66,52,5,48,12,14,3,9,1,4,7,3,3,3,2,14,4,28,16,24,21,28,16,16,13,6,6,4,4,5,3,6,4,3,1,13,5,5,3,5,1,1,1,22]);
            await setcopyright("Texte biblique de la Nouvelle Edition de Genève<br/>Copyright© 1979 <a target='_blank' href='http://www.societebiblique.com'>Société Biblique de Genève</a><br/>Reproduit avec aimable autorisation. Tous droits réservés.");
        }

        await setbible(value);
    }

    const addPage = () => {
        if(activePa < totalChapitre){
            setactivePa(activePa+1);
            //calculerLivreChapitre(activePa+1,listPlage);
        }
    }

    const minusPage = () =>{
        if(activePa > 1){
            setactivePa(activePa-1);
            //calculerLivreChapitre(activePa-1,listPlage);
        }
    }

    const changerPage = (value) => {

        setactivePa(value);
        // calculerLivreChapitre(activePa,listPlage); va être executer par le useEffect

    }


    const changerTheme = () => {
        if(theme === "clair"){
            settheme("sombre");
        }else{
            settheme("clair");
        }
    }

    const rightClick = (e) => {
        if (e.button === 2) {
            e.preventDefault();
            return false;
        }
    }

    let debut = "";
    let bgColor = "#1f2528";
    let fontColor = "#ccd8dc";
    if(listVerset.length > 0)
    {
        debut = listVerset[0].verset.substr(0,70);
    }
    if(theme === "sombre"){
        bgColor = "#1f2528";
        fontColor = "#ccd8dc";
    }else{
        bgColor = "white";
        fontColor = "black";
    }
    return ( 
            <Container className="container" onContextMenu={rightClick} style={{marginBottom: "4em"}}>
                <Helmet>
                    <meta property="og:locale" content="fr_FR" />
                    <meta property="og:type" content="website" />
                    <meta prefix="og: http://ogp.me/ns#" property="og:title" content={`Lecture de la bible : ${book} chapitre ${chapiter}`} />
                    <meta prefix="og: http://ogp.me/ns#" property="og:description" content={`${debut}...`} />
                    <meta property="og:url" content="https://bible-online.fr/lire" />
                    <meta name="keywords" content="Bible, lecture, recherche, zoom, grande taille, Louis Segond, Darby, Segond 21, Martin, Osterwald"/>
                    <meta name="author" content="SAMUEL JACQUINET"/>
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={`${debut}...`} />
                    <meta name="twitter:title" content={`Lecture de la bible : ${book} chapitre ${chapiter}`} />
                    <meta name="twitter:image" content="https://bible-online.fr/Screenshot.png" />
                    <title>Bible online - Lire la Bible</title>
                </Helmet>
                <div className="menuglobal">
                    <MenuDeGauche activeItem={activeItem} changerTheme={changerTheme} changerTaille={changerTaille} changeActiveItem={changeActiveItem}/> 
                    <MenuDeDroite changerBible={changerBible} choixBible={bible} livre={book} chapitre={chapiter} options0={options0} options={options} changerLivre={changerLivre} changerChapitre={changerChapitre}/>
                </div>
                <Card fluid style={{ marginBottom: '4em', backgroundColor: bgColor, color: fontColor}}>
                    <Card.Content>
                        <div style={{float: "left"}}>
                            <p className="livre"><Icon name='book' />{TheBook} / Chapitre {chapiter}</p>
                        </div>
                        <div style={{float: "right"}}>
                            <Icon size="big" name='arrow alternate circle left' onClick={minusPage} style={{cursor: 'pointer'}} />
                            <Icon name='arrow alternate circle right' onClick={addPage} size="big" style={{cursor: 'pointer'}} />    
                        </div>
                    </Card.Content>
                    <Card.Content style={{padding: "0em"}}>
                        <Dimmer active={chargement}  inverted>
                            <Loader inverted content="Chargement de la page..." />
                        </Dimmer>
                        <Page chargement={chargement} listdesversets={listVerset} textStyle={activeItem} taille={taille} livre={book}/>
                        <BasDePage fonce={theme === "sombre" ? true : false} nombredepage={totalChapitre} changerPage={changerPage} page={activePa} total={totalChapitre}/>
                         <div style={{textAlign: "center", padding: "1em", marginBottom: "2em", fontWeight: "semi-bold", color: "#999"}}>
                            <p>{ReactHtmlParser(copyright)}</p>
                         </div>
                    </Card.Content>
                </Card>
            </Container>
    )

}
 
export default Lire;