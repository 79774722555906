import React from 'react';
import { Container, Pagination } from 'semantic-ui-react';

const BasDePage = ({page, changerPage, nombredepage,fonce}) => {

    function handleChangePage(e,{activePage}){
        changerPage(activePage);       
    }

    return ( 
        <Container textAlign='center'>
            <Pagination inverted={fonce} color="black" boundaryRange={0} ellipsisItem={null} totalPages={nombredepage} siblingRange={1} firstItem={null} lastItem={null} activePage={page} onPageChange={handleChangePage}/>
        </Container>
    );

}
 
export default BasDePage;