import React, { Component } from 'react';
import { Menu, Select } from 'semantic-ui-react';

class MenuDeDroite extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            lesoptions: [
                { key: '1', text: 'Bible Louis Segond', value: 'lsg' },
                { key: '3', text: 'Bible Darby', value: 'dby' },
                { key: '4', text: 'Bible Martin', value: 'mar' },
                { key: '5', text: 'Bible Osterwald', value: 'ost' },
              ],
        }
        this.handleChangeBook = this.handleChangeBook.bind(this);
        this.handleChangeChapiter = this.handleChangeChapiter.bind(this);
        this.handleChangeBible = this.handleChangeBible.bind(this);
    }

    handleChangeBook(e, {value}){
        this.props.changerLivre(value);
    }

    handleChangeChapiter(e, {value}){
        this.props.changerChapitre(value);
    }

    handleChangeBible(e, {value}){
        this.props.changerBible(value);
    }

    render() { 
        return ( 
            <div className="menudedroite">

                <div className="menudedroite1">
                    <Menu inverted compact style={{float: "right"}}>
                        <Select value={this.props.choixBible} style={{width: '17em'}} compact selection options={this.state.lesoptions} onChange={this.handleChangeBible}/>
                    </Menu>
                </div>

                <div className="menudedroite2">
                    <Menu compact>
                        <Select value={this.props.livre}  style={{width: '12em'}} compact options={this.props.options0} onChange={this.handleChangeBook}/>
                    </Menu>

                    <Menu compact style={{marginLeft: '1em'}}>
                        <Select value={this.props.chapitre} style={{width: '11em'}} compact selection options={this.props.options} onChange={this.handleChangeChapiter}/>
                    </Menu>
                </div>
            </div>
         );
    }
}
 
export default MenuDeDroite;