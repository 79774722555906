import React,{useState, useEffect} from 'react';
import { Form, Button, Container,Message, Card, Icon, Select, Checkbox } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser'; 
import {Helmet} from "react-helmet";
import shortid from  "shortid";
import {useNavigate} from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const lesoptions = [
    { key: '1', text: 'Bible Louis Segond', value: 'lsg' },
    { key: '3', text: 'Bible Darby', value: 'dby' },
    { key: '4', text: 'Bible Martin', value: 'mar' },
    { key: '5', text: 'Bible Osterwald', value: 'ost' }
  ];

const list1 = ["Genèse","Exode","Lévitique","Nombres","Deutéronome","Josué","Juges","Ruth","1Samuel","2Samuel","1Rois","2Rois","1Chroniques","2Chroniques","Esdras","Néhémie","Esther","Job","Psaumes","Proverbes","Ecclésiaste","Cantique","Esaïe","Jérémie","Lamentations","Ezéchiel","Daniel","Osée","Joël","Amos","Abdias","Jonas","Michée","Nahum","Habakuk","Sophonie","Aggée","Zacharie","Malachie","Matthieu","Marc","Luc","Jean","Actes","Romains","1Corinthiens","2Corinthiens","Galates","Ephésiens","Philippiens","Colossiens","1Thessaloniciens","2Thessaloniciens","1Timothée","2Timothée","Tite","Philémon","Hébreux","Jacques","1Pierre","2Pierre","1Jean","2Jean","3Jean","Jude","Apocalypse"];

function Recherche() {
    
    const [recherchee, setrecherchee] = useState('')

    const [load, setload] = useState(false)

    const [error, seterror] = useState(false)

    const [error2, seterror2] = useState(false)

    const [listVerset, setlistVerset] = useState([])

    const [casee, setcasee] = useState(false)

    const [bible, setbible] = useState("lsg")
   
    const navigate = useNavigate();

    useEffect(() => {
        
        if(listVerset.length > 0 ){

            const recherche = recherchee.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ').trim();

            if(recherche !== ''){

                setload(true);
                seterror(false)
                seterror2(false)
                setlistVerset([]);


                fetch(`https://api.web-labosims.org/bible/v1/${bible}/r/$2y$10$vHbj9FQKirh.S0bF6pLe3ugZdFHBwajZ2OyMRS8yAdVOCsA.zW8B2/${recherche.split(' ').join('-')}/${casee}`)
                .then((response) => response.json())
                .then((data) => { 
                    setlistVerset(data);
                    setload(false);
                })
                .catch((erreur) => {
                    setload(false);
                    seterror2(true)
                    console.log(erreur);
                });
    
            }else{
                setload(false);
                seterror(true)
            }
            
        }

    }, [bible,casee,recherchee])


        useEffect(() => {
        
        if(listVerset.length > 0 ){

            const recherche = recherchee.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ').trim();

            if(recherche !== ''){

                setload(true);
                seterror(false)
                seterror2(false)
                setlistVerset([]);


                fetch(`https://api.web-labosims.org/bible/v1/${bible}/r/$2y$10$vHbj9FQKirh.S0bF6pLe3ugZdFHBwajZ2OyMRS8yAdVOCsA.zW8B2/${recherche.split(' ').join('-')}/${casee}`)
                .then((response) => response.json())
                .then((data) => { 
                    setlistVerset(data);
                    setload(false);
                })
                .catch((erreur) => {
                    setload(false);
                    seterror2(true)
                    console.log(erreur);
                });
    
            }else{
                setload(false);
                seterror(true)
            }
            
        }

    }, [casee])

    const onSearchChange = (evt) => {       
        setrecherchee(evt.target.value);
        setlistVerset([]);
        seterror(false);
    }

    const onSubmit = () => {

        var recherche = recherchee.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ').trim();

        if(recherche !== '' && recherche.length > 2){
            
            setload(true);
            seterror(false);
            seterror2(false);
            setlistVerset([]);


            fetch(`https://api.web-labosims.org/bible/v1/${bible}/r/$2y$10$vHbj9FQKirh.S0bF6pLe3ugZdFHBwajZ2OyMRS8yAdVOCsA.zW8B2/${recherche.split(' ').join('-')}/${casee}`)
                .then((response) => response.json())
                .then((data) => { 
                    setlistVerset(data);
                    setload(false);
                })
                .catch((erreur) => {
                    setload(false);
                    seterror2(true)
                    console.log(erreur);
                });
  
        }else{
            setload(false);
            seterror(true)
        }
    }

    const renderList = () => {
        let listeDesMots;
        if(recherchee.search(" ") !== -1){
            listeDesMots = recherchee.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ').trim().split(" ");
        }
        else
        {
            listeDesMots = recherchee.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ').trim();
        }
        return listVerset.map(leverset => {
            let contenu = leverset.verset;
            if(typeof listeDesMots === "string"){
                var motFinal = listeDesMots;
                //console.log(motFinal);
                motFinal = motFinal.replace(new RegExp(/[aàáâãäå]/gi),"[aàáâãäå]");
                motFinal = motFinal.replace(new RegExp(/[eèéêë]/gi),"[eèéêë]");
                motFinal = motFinal.replace(new RegExp(/[iìíîï]/g),"[iìíîï]");
                motFinal = motFinal.replace(new RegExp(/[uùúûü]/g),"[uùúûü]");
                motFinal = motFinal.replace(new RegExp(/[çc]/g),"[çc]");
                motFinal = motFinal.replace(new RegExp(/[ñn]/g),"[ñn]"); 
                var re = new RegExp(motFinal,"gi");
                var matche = contenu.match(re);
                matche.forEach(element => {
                    contenu = contenu.replace(element,`<mark>${element}</mark>`);
                })
                
            }else{
                listeDesMots.forEach(lemot =>{
                    var motFinal = lemot;
                    motFinal = motFinal.replace(new RegExp(/[aàáâãäå]/gi),"[aàáâãäå]");
                    motFinal = motFinal.replace(new RegExp(/[eèéêë]/gi),"[eèéêë]");
                    motFinal = motFinal.replace(new RegExp(/[iìíîï]/g),"[iìíîï]");
                    motFinal = motFinal.replace(new RegExp(/[uùúûü]/g),"[uùúûü]");
                    motFinal = motFinal.replace(new RegExp(/[çc]/g),"[çc]");
                    motFinal = motFinal.replace(new RegExp(/[ñn]/g),"[ñn]"); 
                    var re = new RegExp(motFinal,"gi");
                    var matche = contenu.match(re);
                    matche.forEach(element => {
                        contenu = contenu.replace(element,`<mark>${element}</mark>`);
                    })
                });
            }
            
            return (
                <Card fluid key={shortid.generate()}>
                <Card.Content style={{fontSize: '1.4em', lineHeight: '1.7em', borderRadius: '1.5em'}}>{ReactHtmlParser(contenu)}</Card.Content>
                <Card.Content style={{cursor:'pointer', fontWeight: 'bold'}} extra onClick={() => {
                            navigate('/lire',{
                                    state:{
                                        livre: list1[leverset.numeropartie-1],
                                        livre2: list1[leverset.numeropartie-1].replace("1","1 ").replace("2","2 ").replace("3","3 "),
                                        chapitre: parseInt(leverset.chapitre),
                                        index: parseInt(leverset.numeropartie-1),
                                        bible: bible
                                    }
                                });
                            }}>
                    <Icon name='book' /> {lesoptions.find((element) => element.value === bible).text} / {leverset.livre.replace("1","1 ").replace("2","2 ").replace("3","3 ")} / Chapitre {leverset.chapitre} / verset {leverset.numeroverset}
                </Card.Content>
            </Card>
            )
        });
    }

    const changerBible = (e,{value}) => {
        setbible(value);
    }

    const changeCase = (e,{checked}) => {
        setcasee(checked);
    }
    
    return ( 
            <Container style={{marginBottom: '5em'}}>
                <Helmet>
                    <meta property="og:locale" content="fr_FR" />
                    <meta property="og:type" content="website" />
                    <meta prefix="og: http://ogp.me/ns#" property="og:title" content="Rechercher dans la Bible" />
                    <meta prefix="og: http://ogp.me/ns#" property="og:description" content="Vous pouvez faire des recherche par mots clés dans les bibles : Louis Segond 1910, Darby, Osterwald et Martin." />
                    <meta property="og:url" content="https://bible-online.fr/recherche" />
                    <meta name="keywords" content="Bible, lecture, recherche, zoom, grande taille, Louis Segond, Darby, Segond 21, Martin, Osterwald"/>
                    <meta name="author" content="SAMUEL JACQUINET"/>
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content="Vous pouvez faire des recherche par mots clés dans les bibles : Louis Segond 1910, Darby, Osterwald et Martin." />
                    <meta name="twitter:title" content="Rechercher dans la Bible" />
                    <meta name="twitter:image" content="https://bible-online.fr/Screenshot.png" />
                    <title>Bible online - Rechercher dans la Bible</title>
                </Helmet>
                <div className="containerRecherche">
                <Message className="introRecherche" style={{marginTop: '2em', textAlign: "justify"}} size="large">
                    <h2>Sur cette page vous pouvez faire des recherches dans la Bible</h2>
                    <p>Vous pouvez faire des recherche par <strong>mots clés</strong> dans les bibles : Louis Segond 1910, Darby, Osterwald et Martin.</p>
                    <p>Dans les résultats des recherches vous pouvez cliquer  sur la référence de chaque verset afin de le voir dans son contexte.</p>
                    <p>Le nombre de résultat d'une recherche est limité à 500 versets.</p>
                </Message>
                <Message style={{marginTop: '0em'}}>
                    <div style={{paddingTop: '1em',paddingBottom: '1em' , paddingLeft: '2em', paddingRight: '2em'}}>                      
                        <Form onSubmit={onSubmit}>
                            <Form.Input loading={load} value={recherchee} onChange={onSearchChange} placeholder='Entrer un ou plusieurs mot clé à chercher dans la bible ...' />  
                            <div className="formRecherche">
                                <Button style={{marginTop: "1em"}} loading={load} color='teal' size='medium'>Rechercher</Button>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <Checkbox label='Sensible à la case' defaultChecked={false} style={{marginRight: '2em'}} onChange={changeCase}/>
                                    <Select value={bible} style={{width: '18em'}} compact selection options={lesoptions} onChange={changerBible}/>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Message>
                <SwitchTransition mode={"out-in"}>
                    <CSSTransition 
                        key={listVerset}
                        timeout={100}
                        classNames='fade'
                        unmountOnExit
                        >
                            <div>{ (listVerset && !error2 && !error && listVerset.length > 0) ? renderList() : undefined }</div>
                    </CSSTransition>
                </SwitchTransition>
                <SwitchTransition mode={"out-in"}>
                    <CSSTransition 
                        key={error || error2}
                        timeout={100}
                        classNames='fade'
                        unmountOnExit
                        >
                            <div>
                                    { error ? <Message 
                                            warning
                                            header='Rien à rechercher'
                                            content='Vous devez entrer des mots clés avant de cliquer sur le bouton "Rechercher"'
                                            /> : null}
                                    { error2 ? <Message 
                                            error
                                            header='Erreur'
                                            content='Une erreur est survenue'
                                    /> : null}
                            </div>
                    </CSSTransition>
                </SwitchTransition>
                </div>
            </Container>
    );

}
 
export default Recherche;