import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css';
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorker.register();

