import React,{useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { Container, Card, Message, Icon, Dimmer } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser'; 
import {Helmet} from "react-helmet";
import shortid from  "shortid";
import { CSSTransition, SwitchTransition } from 'react-transition-group';

function Accueil(){
    
    const [listPartie, setlistPartie] = useState(["Genèse","Exode","Lévitique","Nombres","Deutéronome","Josué","Juges","Ruth","1Samuel","2Samuel","1Rois","2Rois","1Chroniques","2Chroniques","Esdras","Néhémie","Esther","Job","Psaumes","Proverbes","Ecclésiaste","Cantique","Esaïe","Jérémie","Lamentations","Ezéchiel","Daniel","Osée","Joël","Amos","Abdias","Jonas","Michée","Nahum","Habakuk","Sophonie","Aggée","Zacharie","Malachie","Matthieu","Marc","Luc","Jean","Actes","Romains","1Corinthiens","2Corinthiens","Galates","Ephésiens","Philippiens","Colossiens","1Thessaloniciens","2Thessaloniciens","1Timothée","2Timothée","Tite","Philémon","Hébreux","Jacques","1Pierre","2Pierre","1Jean","2Jean","3Jean","Jude","Apocalypse"])
    const [versets, setversets] = useState([])
    const [livre, setlivre] = useState("")
    const [chapitre, setchapitre] = useState("")
    const [plageVerset, setplageVerset] = useState("")
    const [index, setindex] = useState(0)

    const navigate = useNavigate();
    
    useEffect(() => {
        fetch("https://api.web-labosims.org/lsg/v1/r/$2y$10$vHbj9FQKirh.S0bF6pLe3ugZdFHBwajZ2OyMRS8yAdVOCsA.zW8B2/random")
            .then((response) => response.json())
            .then((data) => {
              
                setversets(data);
                setchapitre(data[0].chapitre);
                setlivre(listPartie[data[0].numeropartie-1]);
                setplageVerset(`${data[0].numeroverset} &#x2794; ${data[2].numeroverset}`);
                setindex(data[0].numeropartie-1);

            })
            .catch((erreur) => console.log(erreur));
    }, [])


    const onClickhandler = async () => {
        const table = ["Genèse","Exode","Lévitique","Nombres","Deutéronome","Josué","Juges","Ruth","1 Samuel","2 Samuel","1 Rois","2 Rois","1 Chroniques","2 Chroniques","Esdras","Néhémie","Esther","Job","Psaumes","Proverbes","Ecclésiaste","Cantique","Esaïe","Jérémie","Lamentations","Ezéchiel","Daniel","Osée","Joël","Amos","Abdias","Jonas","Michée","Nahum","Habakuk","Sophonie","Aggée","Zacharie","Malachie","Matthieu","Marc","Luc","Jean","Actes","Romains","1 Corinthiens","2 Corinthiens","Galates","Ephésiens","Philippiens","Colossiens","1 Thessaloniciens","2 Thessaloniciens","1 Timothée","2 Timothée","Tite","Philémon","Hébreux","Jacques","1 Pierre","2 Pierre","1 Jean","2 Jean","3 Jean","Jude","Apocalypse"];
        
        const lelivre = listPartie[index];
        const livre = table[index];

        navigate('/lire',{
            state:{
                livre: lelivre,
                livre2: livre,
                chapitre: parseInt(chapitre),
                index: parseInt(index),
                bible: "lsg"
            }
        });
        
    }

    const table = ["Genèse","Exode","Lévitique","Nombres","Deutéronome","Josué","Juges","Ruth","1 Samuel","2 Samuel","1 Rois","2 Rois","1 Chroniques","2 Chroniques","Esdras","Néhémie","Esther","Job","Psaumes","Proverbes","Ecclésiaste","Cantique","Esaïe","Jérémie","Lamentations","Ezéchiel","Daniel","Osée","Joël","Amos","Abdias","Jonas","Michée","Nahum","Habakuk","Sophonie","Aggée","Zacharie","Malachie","Matthieu","Marc","Luc","Jean","Actes","Romains","1 Corinthiens","2 Corinthiens","Galates","Ephésiens","Philippiens","Colossiens","1 Thessaloniciens","2 Thessaloniciens","1 Timothée","2 Timothée","Tite","Philémon","Hébreux","Jacques","1 Pierre","2 Pierre","1 Jean","2 Jean","3 Jean","Jude","Apocalypse"];
    return ( 
            <Container text style={{ marginTop: '1em', paddingBottom : '1em'}}>
                <Helmet>
                    <meta property="og:locale" content="fr_FR" />
                    <meta property="og:type" content="website" />
                    <meta prefix="og: http://ogp.me/ns#" property="og:title" content="Outils de lecture et de recherche biblique" />
                    <meta prefix="og: http://ogp.me/ns#" property="og:description" content="Lecture de différentes versions de la Bible avec la taille de police de son choix. Site adapté à la lecture sur ordinateur, téléphone et tablette.
                    Possibilité de faire des recherches dans différentes versions de la Bible." />
                    <meta property="og:url" content="https://bible-online.fr/" />
                    <meta name="keywords" content="Bible, lecture, lire la bible, recherche, thème sombre, thème foncé, thème clair, grande taille, Louis Segond, Darby, Martin, Osterwald"/>
                    <meta name="author" content="SAMUEL JACQUINET"/>
                    <meta name="copyright" content="SAMUEL JACQUINET"/>
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content="Lecture de différentes versions de la Bible avec la taille de police de son choix. Site adapté à la lecture sur ordinateur, téléphone et tablette.
                    Possibilité de faire des recherches dans différentes versions de la Bible." />
                    <meta name="twitter:title" content="Outils de lecture et de recherche biblique" />
                    <meta name="twitter:image" content="https://bible-online.fr/Screenshot.png" />
                    <title>Bible online - Accueil</title>
                </Helmet>
                <Message style={{textAlign: "justify", marginBottom: "0.7em", padding: "1em"}} compact size="large">
                    <h2>Bienvenue sur le site : bible-online.fr</h2>
                    <p style={{margin: '0.3em 0'}}>Ce site permet de lire la bible dans différentes versions ainsi que de faire des recherches dans toutes ces versions de la bible.</p>
                    <p style={{margin: '0.3em 0'}}>Ce site peux être consulté aussi bien sur ordinateur que sur <strong>tablette</strong> ou <strong>téléphone</strong></p>
                    <p style={{margin: '0.3em 0'}}>Lorsque vous lisez la bible sur ce site vous pouvez choisir la taille de la police et le mode de lecture de la bible (en mode compact ou aéré et thème clair ou sombre).</p>
                    <p style={{margin: '0.3em 0'}}>Lorsque vous faite des recherches dans la bible sur ce site vous pouvez choisir la version de la bible dans laquelle vous souhaitez faire des recherches et choisir la sensibilité à la case ou non (Prise en compte des majuscules/minuscules).</p>
                </Message>
                <Card fluid style={{ marginBottom: '3em', textAlign: 'justify', marginTop: "0.5em" }}>
                    <Card.Content style={{padding: '0.5em'}}><h3>Un passage de la bible choisit au hasard :</h3></Card.Content>
                    <SwitchTransition mode={"out-in"}>
                        <CSSTransition 
                            key={versets}
                            timeout={200}
                            classNames='fade'
                            unmountOnExit
                            >
                                <Card.Content style={{paddingBottom: "0.4em"}}>
                                        <p>{
                                            versets.length > 0 ? versets.map(leverset => {
                                                return (
                                                    <span key={shortid.generate()}>&nbsp;<strong><sup>{leverset.numeroverset}</sup></strong>&nbsp;{leverset.verset}</span>
                                                )
                                            })
                                            : (
                                                <Dimmer inverted active >
                                                    Connexion au serveur....
                                                </Dimmer>
                                            )
                                        }</p>
                                </Card.Content>
                        </CSSTransition>
                    </SwitchTransition>
                    <Card.Content style={{cursor:'pointer', fontWeight: 'bold'}} extra onClick={onClickhandler}>
                        <Icon name='book'/>Louis Segond / {table[index]} / Chapitre {chapitre} / Versets {ReactHtmlParser(plageVerset)}
                    </Card.Content>
                </Card>
            </Container>
        );

}
 
export default Accueil;