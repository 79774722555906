import React from 'react';
import { Menu,Segment } from 'semantic-ui-react';
import { Link,useLocation } from 'react-router-dom';

function Head() {
    
    let activeItem = useLocation();

    return ( 
        <header>
          <Segment inverted vertical>
            <Menu size='large' stackable pointing inverted secondary >
              <Menu.Item
                  name='accueil' 
                  active={activeItem.pathname === '/accueil'} 
                  as={Link} 
                  to="/"
                  >Accueil
              </Menu.Item>
              <Menu.Item
                  name='lire' 
                  active={activeItem.pathname === '/lire'} 
                  as={Link} 
                  to="/lire"   
                  >
                  Lire la Bible
              </Menu.Item>
              <Menu.Item 
                  name='recherche' 
                  active={activeItem.pathname === '/recherche'} 
                  as={Link} 
                  to="/recherche" 
                  
                  >
                  Rechercher dans la Bible
              </Menu.Item>
              <Menu.Item 
                  name='contact' 
                  active={activeItem.pathname === '/contact'} 
                  as={Link} 
                  to="/contact"        
                  >
                  Contact
              </Menu.Item>
            </Menu>
          </Segment>
        </header>
         );

}
 
export default Head;