import React,{useState,useEffect, Fragment} from 'react';
import { Container, Dimmer } from 'semantic-ui-react';
import shortid from  "shortid";
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const Page = ({ listdesversets,taille,textStyle }) => {

    return (
        <SwitchTransition mode={"out-in"}>
            <CSSTransition 
                key={listdesversets}
                timeout={200}
                classNames='fade'
                unmountOnExit
                >
                <Container textAlign='justified'>
                    <div id="haut" className="laPage">
                    {
                        listdesversets.length > 0 && (
                            <Fragment>
                                { textStyle === 'compact' ?
                                    listdesversets.map(leverset => {
                                            return (
                                                <p key={shortid.generate()} style={{fontSize: `${taille}em`}}><strong><sup>{leverset.numeroverset}</sup></strong>&nbsp;{leverset.verset}</p>
                                            )
                                        })
                                    : <p style={{fontSize: `${taille}em`}}>{
                                        listdesversets.map(leverset => {
                                            return (
                                                <span key={shortid.generate()}>&nbsp;<strong><sup>{leverset.numeroverset}</sup></strong>&nbsp;{leverset.verset}</span>
                                            )})
                                    }</p>
                                }
                            </Fragment>
                        ) 
                    }
                    </div>
                </Container>
            </CSSTransition>
        </SwitchTransition>
     );

}
 
export default Page;