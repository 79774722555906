import React,{Component} from 'react';
import { Container,Button, Message, Loader, Dimmer, Card } from 'semantic-ui-react';
import {Form,Input,TextArea} from 'semantic-ui-react-form-validator';
import {Helmet} from "react-helmet";

class Contact extends Component {
    
    constructor(props){
        super(props);
        this.state = { 
            nom: '',
            prenom: '',
            email: '',
            message: '',
            envoye: false,
            success: false,
            load: false,
            error: false,
            error2 : false
         }
    }
    

    onChange = (evt) => {
        this.setState({
            [evt.target.name] : evt.target.value
        })
    }

    onSubmit = async (evt) => {

        await this.setState({
            load: true,
            error: false
        })

        var done = false;

        await fetch("https://post.web-labosims.org/index.php", { 
                method: "POST", 
                body: JSON.stringify({ 
                    email: this.state.email, 
                    prenom: this.state.prenom,
                    nom: this.state.nom,
                    message: this.state.message
            }), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            } 
            }).then(response => response.json()) 
            .then(data => {
                if(data["status"] === "success"){
                    done = true;
                }else{
                    done = false;
                }
            }).catch((error) => {
                done = false;
                this.setState({
                    error2 : true,
                })
            })

        if(done === true){
            await this.setState({
                success: true,
                load: false,
                email: '',
                nom: '',
                prenom: '',
                message: '',
                error: false
            })
        }else{
            await this.setState({
                success: false,
                load: false,
                error: true
            })
        }

    }

    render() {
        return ( 
            <Container style={{marginTop: '2em', marginBottom: "4em"}}>
                <Helmet>
                    <meta property="og:locale" content="fr_FR" />
                    <meta property="og:type" content="website" />
                    <meta prefix="og: http://ogp.me/ns#" property="og:title" content="Contact - Bible online fr" />
                    <meta prefix="og: http://ogp.me/ns#" property="og:description" content="Pour toute question, pour signaler un souci, un bug, une faute de frappe, vous pouvez me contacter via le formulaire de contact." />
                    <meta property="og:url" content="https://bible-online.fr/contact" />
                    <meta name="keywords" content="Bible, lecture, recherche, zoom, grande taille, Louis Segond, Darby, Martin, Osterwald"/>
                    <meta name="author" content="SAMUEL JACQUINET"/>
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content="Pour toute question, pour signaler un souci, un bug, une faute de frappe, vous pouvez me contacter via le formulaire de contact." />
                    <meta name="twitter:title" content="Contact" />
                    <meta name="twitter:image" content="https://bible-online.fr/Screenshot.png" />
                    <title>Bible online - Contact</title>
                </Helmet>
                <Card style={{width: "100%"}}>
                <Message style={{fontSize: '1.2em', fontWeight: 'bold'}}
                    content='Pour toute question, pour signaler un souci, un bug, une faute de frappe, vous pouvez me contacter via le formulaire de contact ci-dessous :'
                />
                {
                    this.state.error ? <Message
                                            error
                                            header="Le message n'a pas pu être envoyé"
                                            content="Une erreur est survenue lors de l'envoi du mail"
                                            style={{marginTop: "1em", marginLeft: "2em", marginRight: "2em"}}
                                        /> : null
                }
                {
                    this.state.success ? <Message
                                                success
                                                header='Message envoyé'
                                                content="Votre message vient d'être envoyé au propriétaire du site"
                                                style={{marginTop: "1em", marginLeft: "2em", marginRight: "2em"}}
                                            /> : null
                }
                <Form 
                style={{padding: '0.5em 2em 0.5em 2em'}}
                ref="form"
                onSubmit={this.onSubmit} 
                >
                {
                    this.state.error2 === false ? (
                        <Dimmer active={this.state.load}  inverted>
                            <Loader inverted content="En cours d'envoi ..." />
                        </Dimmer>
                    ) : (
                        <Dimmer active>
                        Une erreur est survenue lors de la connexion au serveur....
                        </Dimmer>
                    )
                }
                    
                    <Input 
                    type="text"
                    label="Nom"
                    onChange={(e)=>{this.setState({nom:e.target.value})}} 
                    value={this.state.nom} 
                    validators={['required']} 
                    errorMessages={['Votre nom est obligatoire']} 
                    width={6} 
                    />
                    <Input 
                    type="text"
                    label="Prenom"
                    onChange={(e)=>{this.setState({prenom:e.target.value})}} 
                    value={this.state.prenom} 
                    validators={['required']} 
                    errorMessages={['Votre prenom est obligatoire']} 
                    width={6} 
                    />
                    <Input 
                    type="mail"
                    label="Email"
                    onChange={(e)=>{this.setState({email:e.target.value})}} 
                    value={this.state.email} 
                    validators={['isEmail','required']} 
                    errorMessages={['Entrer une adresse email valide SVP','Entrer une adresse email SVP']} 
                    width={6} 
                    />
                    <TextArea
                    label="Message"
                    validators={['required']}
                    errorMessages={['Vous devez écrire quelque chose']}
                    value={this.state.message}
                    onChange={e=>{this.setState({message:e.target.value})}}
                    />
                    <Button style={{marginTop: '0.5em', marginBottom: '0.5em'}} color="teal">Envoyer</Button>
                </Form>
                </Card>
            </Container>
         );
    }
}
 
export default Contact;