import React from 'react';

const Foot = () => {
    return (
        <footer>
          <p style={{ fontSize : "0.9em", verticalAlign : "middle" }}>Site réalisé par S. JACQUINET - Version 1.0.9 du 30/07/2022</p>
        </footer>
    )
}

export default Foot;