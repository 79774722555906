import React, { Fragment, Suspense, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import { Dimmer, Loader } from 'semantic-ui-react';
import Head from './components/Head';
import Foot from './components/Foot';
import Accueil from './components/Accueil';
import Recherche from './components/Recherche';
import Lire from './components/Lire';
import Contact from './components/Contact';
import Page404 from './components/Page404';


function App() {

  const [livre, setlivre] = useState(null)

  const [livre2, setlivre2] = useState(null)

  const [chapitre, setchapitre] = useState(null)

  const [index, setindex] = useState(null)

  const [bible, setbible] = useState(null)

  return (
    <Fragment>
      <Router>
        <Head />
        <Suspense fallback={<Dimmer active><Loader content="En cours de chargement ..." /></Dimmer>}>
          <Routes>
            <Route path="/" element={<Accueil/>}/>
            <Route path="/recherche" element={<Recherche/>}/>
            <Route path="/lire" element={<Lire/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="*" element={<Page404/>}/>
            <Route path="/*" element={<Accueil/>}/>
          </Routes>
        </Suspense>
        <Foot/>
      </Router>
    </Fragment>
  );
}

export default App;
